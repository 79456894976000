import hamburger from "./hamburger"
import displayCookieConsent from "./Cookies/cookieConsent"
import toggleDropdown from "./toggleDropdown"
import toggleDarkMode from "./toggleDarkMode"

displayCookieConsent()
hamburger()
toggleDropdown()
toggleDarkMode()

