export default function toggleDropdown() {
    const dropdowns = document.getElementsByClassName("subpage");

    for (const dropdown in dropdowns) {
        if (dropdowns.hasOwnProperty(dropdown)) {
            const element = dropdowns[dropdown];
            const button = element.children[0].children[0];
            const wall = element.children[1];

            button.addEventListener("click", () => {
                wall.classList.toggle("hidden");
                button.setAttribute("aria-expanded", button.getAttribute("aria-expanded") === "true" ? "false" : "true");
            });

            document.addEventListener('click', (e) => {
                if (!element.contains(e.target as Node) && e.target !== element && !wall.classList.contains("hidden")) {
                    wall.classList.add("hidden");
                    button.setAttribute("aria-expanded", button.getAttribute("aria-expanded") === "true" ? "false" : "true");
                }
            });
        }
    }
}
