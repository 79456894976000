/**
 * @param name Name of the cookie 
 * @param value Value of the cookie
 * @param expires When to expire the cookie, in seconds, from the time of setting the cookie
 * @param [domain=false] Sets the 'subdomain' header
 * @param [path="/"] What path does the cookie apply to
 * @param [sameSite="lax"] Sets the 'SameSite' header
 * @param [isSecure=true] Transmitted via HTTPS by default
 * @param [isPartitioned=false] Sets the 'partitioned' header
 */
export function setCookie(
    name: string,
    value: string,
    expires: number,
    domain: string | false = false,
    path = "/",
    sameSite = "lax",
    isSecure = true,
    isPartitioned = false
) {
    const cookieName = name + "=" + value
    const date = new Date()
    date.setTime(date.getTime() + expires)
    const cookieExpires = ";expires=" + date.toUTCString()
    const cookieDomain = domain ? ";subdomain=" + domain : ""
    const cookiePath = ";path=" + path
    const cookieSameSite = ";SameSite=" + sameSite
    const cookieSecure = isSecure ? ";secure" : ""
    const cookiePartitioned = isPartitioned ? ";partitioned" : ""

    document.cookie = cookieName + cookieExpires + cookiePath + cookieDomain
        + cookieSameSite + cookieSecure + cookiePartitioned
}

/**
 * @returns Array of cookies with keys { name, value }
 */
export function getCookies() {
    return decodeURIComponent(document.cookie)
        .split(";")
        .map((item) => {
            const [name, value] = item.trim().split("=")
            return { "name": name, "value": value }
        })
}

/**
 * @param name Name of the cookie
 * 
 * @returns Value of the cookie, false if not found
 */
export function getCookie(name: string): string | false {
    const cookie = getCookies().find((value) => value.name === name)

    return cookie ? cookie.value : false
}
