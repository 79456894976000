import { setCookie, getCookie } from "./cookies"

export default function displayCookieConsent() {
    const cookieConsent = document.getElementById("cookie-consent")
    const accept = document.getElementById("cookie-consent-accept")
    const reject = document.getElementById("cookie-consent-reject")

    accept.addEventListener("click", () => {
        setCookie("cookie-consent", "true", 30 * 24 * 60 * 60)  // 30 days
        cookieConsent.classList.add("invisible")
    })

    reject.addEventListener("click", () => {
        const obj = { value: true, expires: new Date().getTime() + 30 * 24 * 60 * 60 }  // 30 days
        localStorage.setItem("isCookieRejected", JSON.stringify(obj))
        cookieConsent.classList.add("invisible")
    })

    document.addEventListener("DOMContentLoaded", () => {
        if (cookieConsent.classList.contains("invisible")) {
            const isRejected = JSON.parse(localStorage.getItem("isCookieRejected"))
            const isExpired = isRejected !== null ? isRejected.expires < new Date().getTime() : true

            if (isExpired) {
                localStorage.removeItem("isCookieRejected")
            }

            const isRejectedValue = (isRejected !== null) ? isRejected.value : false

            if (getCookie("cookie-consent") !== "true" && !isRejectedValue) {
                cookieConsent.classList.remove("invisible")
            }
        }
    })
}
