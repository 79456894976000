const darkModeBtn = document.querySelectorAll(".dark-mode-btn");

const darkModeIconSun = document.getElementById("dark-mode-icon-sun");
const darkModeIconMoon = document.getElementById("dark-mode-icon-moon");

const darkModeSunTooltip = document.getElementById("tooltip-dark-mode-sun");
const darkModeMoonTooltip = document.getElementById("tooltip-dark-mode-moon");

// Icons on the mobile version
const iconMoonM = document.getElementById("toggle-dark-mode-m");
const iconSunM = document.getElementById("toggle-light-mode-m");

export default function toggleDarkMode() {
    for (const btn in darkModeBtn) {
        if (darkModeBtn.hasOwnProperty(btn)) {
            const element = darkModeBtn[btn];

            // Change between light- and dark-mode on click
            element.addEventListener("click", () => {
                const html = document.getElementById("html");

                // Change theme in local storage
                if (!html.classList.contains("dark")) {
                    localStorage.theme = "dark";
                } else {
                    localStorage.theme = "light";
                }

                // Toggle class 'dark' on html
                html.classList.toggle(
                    "dark",
                    localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia(("preffered-color-scheme: dark")).matches)
                );

                // Change the visible icon
                darkModeIconSun.classList.toggle("hidden");
                darkModeIconMoon.classList.toggle("hidden");
                iconSunM.classList.toggle("hidden");
                iconMoonM.classList.toggle("hidden");

                // Hide the tooltips
                hideTooltips();
                showTooltips();
            });

            // Show tooltips on hover
            element.addEventListener("mouseenter", showTooltips);
            element.addEventListener("mouseleave", hideTooltips);
        }
    }

    // If dark mode cookie is present, add a 'dark' class to the html
    document.addEventListener("DOMContentLoaded", () => {
        const html = document.getElementById("html");

        if (html.classList.contains("dark")) {
            if (darkModeIconSun.classList.contains("hidden")) {
                darkModeIconSun.classList.remove("hidden");
            }
            if (iconSunM.classList.contains("hidden")) {
                iconSunM.classList.remove("hidden");
            }
        } else {
            if (darkModeIconMoon.classList.contains("hidden")) {
                darkModeIconMoon.classList.remove("hidden");
            }
            if (iconMoonM.classList.contains("hidden")) {
                iconMoonM.classList.remove("hidden");
            }
        }
    });
}

function showTooltips() {
    if (!darkModeIconMoon.classList.contains("hidden")) {
        // Icon is visible
        if (darkModeMoonTooltip.classList.contains("invisible")) {
            // Show the tooltip, if not visible already
            darkModeMoonTooltip.classList.remove("invisible");
        }
    } else {
        // Icon is not visible
        if (!darkModeMoonTooltip.classList.contains("invisible")) {
            // Hide the tooltip, if not hidden already
            darkModeMoonTooltip.classList.add("invisible");
        }
    }

    // The same logic for the other icon
    if (!darkModeIconSun.classList.contains("hidden")) {
        if (darkModeSunTooltip.classList.contains("invisible")) {
            darkModeSunTooltip.classList.remove("invisible");
        } else {
            if (!darkModeSunTooltip.classList.contains("invisible")) {
                darkModeSunTooltip.classList.add("invisible");
            }
        }
    }
}


function hideTooltips() {
    if (!darkModeIconMoon.classList.contains("hidden")) {
        // Icon is visible
        if (!darkModeMoonTooltip.classList.contains("invisible")) {
            // Make the toolti not visible, if not done already
            darkModeMoonTooltip.classList.add("invisible");
        }
    } else {
        // Icon is already invisible
        if (!darkModeMoonTooltip.classList.contains("invisible")) {
            // Make the tooltip not visible, if not done already
            darkModeMoonTooltip.classList.add("invisible");
        }
    }

    // The same logic for the other icon
    if (!darkModeIconSun.classList.contains("hidden")) {
        if (!darkModeSunTooltip.classList.contains("invisible")) {
            darkModeSunTooltip.classList.add("invisible");
        }
    } else {
        if (!darkModeSunTooltip.classList.contains("invisible")) {
            darkModeSunTooltip.classList.add("invisible");
        }
    }
}
